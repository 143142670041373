<template>
  <div>

    <div class="row">
      <div class="col-md-12">
        <Card :title="'Tambah Karyawan'">
          <template v-slot:body>
            <HrForm :form="form" :route="'employees'" :password="true"/>
          </template>
        </Card>
      </div>
    </div>

  </div>
</template>

<script>

import Card from '@/view/content/Card.vue'
import HrForm from '@/component/human-resource/Form.vue'
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module"

export default {

  data(){
    return {
      form:{
        email: "",
        name: "",
        birt_place: "",
        birt_date: "",
        address: "",
        phone: "",
        mobile_phone: "",
        id_card_number: "",
        employee_number_id:"",
        password: "",
        password_confirmation: "",
        position_id:"",
        department_id:"",
        photo: [],
        photo_name: "/images/default-profile.svg"
      }
    }
  },

  name: 'Add',

  components: {
    Card,
    HrForm
  },
  

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Karyawan" },
      { title: "Daftar Karyawan", route: "/human-resource/employee/list" },
      { title: "Tambah Karyawan" },
    ])
  },

}
</script>